import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                py: 2,
                textAlign: 'center',
                borderTop: 1,
                borderColor: 'divider',
            }}
        >
            <Typography variant="body2" color="text.secondary">
                © {new Date().getFullYear()} AgriPlanner. Wszelkie prawa zastrzeżone.
            </Typography>
        </Box>
    );
};

export default Footer;