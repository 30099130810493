import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, TextField, Typography, Box } from '@mui/material';
import { completeLogin } from '../services/apiService';
import { AuthContext } from '../context/AuthContext';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const { login } = useContext(AuthContext);

  const handleLogin = async () => {
    setErrorMessage('');
    try {
      const response = await completeLogin({ username, password });
      if (response.data.accessToken && response.data.refreshToken) {
        login(response.data.accessToken, response.data.refreshToken);
        navigate('/');
      } else {
        setErrorMessage('Nieprawidłowy login lub hasło.');
      }
    } catch (error) {
      setErrorMessage('Błąd podczas logowania.');
      console.error('Error during login process', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: '20px',
        backgroundColor: '#ffffff',
      }}
    >
      <img src='./agri.svg' className="w-32 mb-8" alt="AGRI Planner Logo" />
      <Box
        sx={{
          width: '100%',
          maxWidth: '400px',
          textAlign: 'center',
          backgroundColor: 'white',
          padding: '30px',
          borderRadius: '8px',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: '1px',
            marginBottom: '24px',
          }}
        >
          Logowanie
        </Typography>
        <TextField
          fullWidth
          label="Nazwa użytkownika"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyPress={handleKeyPress}
          margin="normal"
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#e0e0e0',
              },
              '&:hover fieldset': {
                borderColor: '#000000',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#000000',
              },
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#000000',
            },
          }}
        />
        <TextField
          fullWidth
          label="Hasło"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={handleKeyPress}
          margin="normal"
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#e0e0e0',
              },
              '&:hover fieldset': {
                borderColor: '#000000',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#000000',
              },
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#000000',
            },
          }}
        />
        <Button
          variant="contained"
          fullWidth
          onClick={handleLogin}
          sx={{
            marginTop: '24px',
            marginBottom: '16px',
            backgroundColor: '#000000',
            color: '#ffffff',
            padding: '12px',
            textTransform: 'uppercase',
            fontWeight: 600,
            letterSpacing: '0.5px',
            '&:hover': {
              backgroundColor: '#333333',
            },
          }}
        >
          Zaloguj się
        </Button>
        {errorMessage && (
          <Typography
            color="error"
            sx={{
              marginTop: '16px',
              fontSize: '0.875rem',
            }}
          >
            {errorMessage}
          </Typography>
        )}
        <Box
          sx={{
            marginTop: '24px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Link
            to="/reset-password"
            style={{
              textDecoration: 'none',
              color: '#000000',
              fontSize: '0.875rem',
              fontWeight: 500,
            }}
          >
            Przypomnienie hasła
          </Link>
          <Link
            to="/register"
            style={{
              textDecoration: 'none',
              color: '#000000',
              fontSize: '0.875rem',
              fontWeight: 500,
            }}
          >
            Rejestracja
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;