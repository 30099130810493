import axios from 'axios';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://agriplanner.app/api';
// Tworzenie instancji axios
const api = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Dodawanie tokena do nagłówków za pomocą interceptorów
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Obsługa odpowiedzi (opcjonalnie)
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Możesz tutaj obsłużyć odświeżenie tokena, wylogowanie itd.
    return Promise.reject(error);
  }
);

// Auth endpoints
export const refreshToken = () => api.post('/auth/refresh-token');
export const getUserRole = () => api.get('/auth/users/role');
export const getUserDetails = () => api.get('/auth/users/details');
export const initiateGoogleOAuth = () => api.get('/auth/google');
export const googleOAuthCallback = () => api.get('/auth/google/callback');

// User management
export const registerUser = (userData) => api.post('/users/register', userData);
export const startLogin = (email) => api.post('/users/loginStart', { email });
export const verifyCode = (code) => api.post('/users/verify-code', { code });
export const completeLogin = (loginData) => api.post('/users/login', loginData);
export const requestPasswordReset = (email) => api.post('/users/request-reset-password', { email });
export const resetPassword = (resetToken, password) => api.post(`/users/reset-password/${resetToken}`, { password });
export const logoutUser = () => api.post('/users/logout');

// Profile management
export const getProfile = () => api.get('/users/myprofile');
export const updateProfile = (profileData) => api.put('/users/myprofile', profileData);
export const changePassword = (passwordData) => api.put('/users/myprofile/change-password', passwordData);

// Business account
export const getBusinessAccount = () => api.get('/business-account');
export const addBusinessAccount = (accountData) => api.post('/business-account', accountData);
export const updateBusinessAccount = (accountData) => api.put('/business-account', accountData);
export const deleteBusinessAccount = () => api.delete('/business-account');

// Machines
export const getMachines = () => api.get('/machines');
export const addMachine = (machineData) => api.post('/machines', machineData);
export const updateMachine = (id, machineData) => api.put(`/machines/${id}`, machineData);
export const deleteMachine = (id) => api.delete(`/machines/${id}`);
export const getMachineDetails = (id) => api.get(`/machines/${id}`);
export const getMachinesByUser = () => api.get('/machines-by-user');
export const getMachineProducers = () => api.get('/machines-producers');
export const getMachineTypes = () => api.get('/machines-types');

// Personal Cars
export const getPersonalCars = () => api.get('/personal-cars');
export const addPersonalCar = (carData) => api.post('/personal-cars', carData);
export const updatePersonalCar = (id, carData) => api.put(`/personal-cars/${id}`, carData);
export const deletePersonalCar = (id) => api.delete(`/personal-cars/${id}`);
export const getPersonalCarDetails = (id) => api.get(`/personal-cars/${id}`);
export const getPersonalCarsByUser = () => api.get('/personal-cars-by-user');
export const getCarBrands = () => api.get('/car-brands');
export const getCarModels = (brand) => api.get(`/car-models/${brand}`);
export const getCarProducers = () => api.get('/car-producers');
export const getCarTypes = () => api.get('/car-types');


// Notifications
export const saveSubscription = (subscriptionData) => api.post('/save-subscription', subscriptionData);
export const sendNotification = (notificationData) => api.post('/send-notification', notificationData);
export const getNotificationSettings = () => api.get('/notification-settings');
export const updateNotificationSettings = (settingsData) => api.put('/notification-settings', settingsData);

// Permissions
export const getPermissions = (role) => api.get(`/permissions/${role}`);
export const updatePermissions = (role, permissionsData) => api.put(`/permissions/${role}`, permissionsData);
export const addPermission = (role, permission) => api.post(`/permissions/${role}/add`, { permission });
export const removePermission = (role, permission) => api.post(`/permissions/${role}/remove`, { permission });

// Reminders
export const getAllReminders = () => api.get('/reminders');
export const addReminder = (reminderData) => api.post('/reminders', reminderData);
export const updateReminder = (id, reminderData) => api.put(`/reminders/${id}`, reminderData);
export const deleteReminder = (id) => api.delete(`/reminders/${id}`);
export const getReminderDetails = (id) => api.get(`/reminders/${id}`);
// Service cards
export const getServiceCards = () => api.get('/servicecards');
export const addServiceCard = (serviceCardData) => api.post('/servicecards', serviceCardData);
export const updateServiceCard = (id, serviceCardData) => api.put(`/servicecards/${id}`, serviceCardData);
export const deleteServiceCard = (id) => api.delete(`/servicecards/${id}`);
export const getServiceCardDetails = (id) => api.get(`/servicecards/${id}`);

// Service requests
export const createServiceRequest = (requestData) => api.post('/service-requests', requestData);
export const updateServiceRequest = (id, requestData) => api.put(`/service-requests/${id}`, requestData);
export const deleteServiceRequest = (id) => api.delete(`/service-requests/${id}`);
export const getServiceRequestDetails = (id) => api.get(`/service-requests/${id}`);

// Service schedules
export const createServiceSchedule = (scheduleData) => api.post('/service-schedule', scheduleData);
export const updateServiceSchedule = (id, scheduleData) => api.put(`/service-schedule/${id}`, scheduleData);
export const deleteServiceSchedule = (id) => api.delete(`/service-schedule/${id}`);
export const getServiceScheduleDetails = (id) => api.get(`/service-schedule/${id}`);

// Spare parts
export const createSparePart = (partData) => api.post('/spare-parts', partData);
export const updateSparePart = (id, partData) => api.put(`/spare-parts/${id}`, partData);
export const deleteSparePart = (id) => api.delete(`/spare-parts/${id}`);
export const getSparePartDetails = (id) => api.get(`/spare-parts/${id}`);

// Technical documents
export const getTechnicalDocuments = () => api.get('/technical-documents');
export const createTechnicalDocument = (documentData) => api.post('/technical-documents', documentData);
export const updateTechnicalDocument = (id, documentData) => api.put(`/technical-documents/${id}`, documentData);
export const deleteTechnicalDocument = (id) => api.delete(`/technical-documents/${id}`);
export const getTechnicalDocumentDetails = (id) => api.get(`/technical-documents/${id}`);

// Warranties
export const getWarranties = () => api.get('/warranties');
export const createWarranty = (warrantyData) => api.post('/warranties', warrantyData);
export const updateWarranty = (id, warrantyData) => api.put(`/warranties/${id}`, warrantyData);
export const deleteWarranty = (id) => api.delete(`/warranties/${id}`);
export const getWarrantyDetails = (id) => api.get(`/warranties/${id}`);