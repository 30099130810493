import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Autocomplete } from '@mui/material';
import { getMachineProducers, getMachineTypes, addMachine, updateMachine, getMachineDetails } from '../services/apiService';
import { useNavigate, useParams } from 'react-router-dom';

const MachineForm = ({ isEditMode = false }) => {
    const [machine, setMachine] = useState({
        producer: '',
        model: '',
        yearOfProduction: '',
        type: '',
        VIN: '',
        meter: ''
    });
    const [producers, setProducers] = useState([]);
    const [types, setTypes] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const producersResponse = await getMachineProducers();
                const sortedProducers = producersResponse.data.sort((a, b) =>
                    a.producent.localeCompare(b.producent)
                );
                setProducers(sortedProducers);

                const typesResponse = await getMachineTypes();
                setTypes(typesResponse.data);

                if (isEditMode) {
                    const machineResponse = await getMachineDetails(id);
                    setMachine(prevMachine => ({
                        ...prevMachine,
                        ...machineResponse.data,
                        producer: machineResponse.data.producer.toString(),
                        type: machineResponse.data.type.toString()
                    }));
                }
            } catch (error) {
                setErrorMessage('Błąd podczas pobierania danych.');
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [isEditMode, id]);

    const handleInputChange = (e, value, reason, name) => {
        if (reason === 'clear') {
            setMachine({ ...machine, [name]: '' });
        } else if (value && typeof value === 'object') {
            setMachine({ ...machine, [name]: value.id.toString() });
        } else if (e && e.target) {
            const { name, value } = e.target;
            setMachine({ ...machine, [name]: value });
        }
    };

    const handleSubmit = async () => {
        try {
            const machineData = {
                ...machine,
                producer: parseInt(machine.producer, 10),
                type: parseInt(machine.type, 10),
                yearOfProduction: parseInt(machine.yearOfProduction, 10),
                VIN: machine.VIN,
                meter: parseInt(machine.meter, 10),
            };

            if (isEditMode) {
                await updateMachine(id, machineData);
            } else {
                await addMachine(machineData);
            }
            navigate('/machines');
        } catch (error) {
            setErrorMessage('Błąd podczas zapisywania danych.');
            console.error('Error saving machine:', error);
        }
    };

    return (
        <Box sx={{ maxWidth: '500px', margin: 'auto', padding: '20px' }}>
            <Typography variant="h4" gutterBottom>
                {isEditMode ? 'Edycja maszyny' : 'Dodaj nową maszynę'}
            </Typography>
            <Autocomplete
                fullWidth
                options={producers}
                getOptionLabel={(option) => option.producent}
                value={producers.find(p => p.id.toString() === machine.producer) || null}
                onChange={(e, value) => handleInputChange(e, value, 'select-option', 'producer')}
                renderInput={(params) => <TextField {...params} label="Producent" margin="normal" />}
            />
            <TextField
                fullWidth
                label="Model"
                name="model"
                value={machine.model}
                onChange={handleInputChange}
                margin="normal"
            />
            <TextField
                fullWidth
                label="Rok produkcji"
                name="yearOfProduction"
                value={machine.yearOfProduction}
                onChange={handleInputChange}
                margin="normal"
            />
            <TextField
                fullWidth
                label="VIN"
                name="VIN"
                value={machine.VIN}
                onChange={handleInputChange}
                margin="normal"
            />
            <TextField
                fullWidth
                label="Przebieg"
                name="meter"
                value={machine.meter}
                onChange={handleInputChange}
                margin="normal"
            />
            <Autocomplete
                fullWidth
                options={types}
                getOptionLabel={(option) => option.type}
                value={types.find(t => t.id.toString() === machine.type) || null}
                onChange={(e, value) => handleInputChange(e, value, 'select-option', 'type')}
                renderInput={(params) => <TextField {...params} label="Typ maszyny" margin="normal" />}
            />
            {errorMessage && <Typography color="error">{errorMessage}</Typography>}
            <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ marginTop: '20px' }}>
                {isEditMode ? 'Zapisz zmiany' : 'Dodaj maszynę'}
            </Button>
        </Box>
    );
};

export default MachineForm;