import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Dialog, DialogTitle, DialogContent, CircularProgress } from '@mui/material';
import { getServiceCards, addServiceCard, updateServiceCard, getMachines, getPersonalCars } from '../services/apiService';
import ServiceCardsList from './ServiceCardsList';
import ServiceCardForm from './ServiceCardForm';

const ServiceCards = () => {
    const [serviceCards, setServiceCards] = useState([]);
    const [machines, setMachines] = useState([]);
    const [cars, setCars] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [editingCard, setEditingCard] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchServiceCards();
        fetchMachines();
        fetchCars();
    }, []);

    const fetchServiceCards = async () => {
        try {
            setLoading(true);
            const response = await getServiceCards();
            setServiceCards(response.data);
            setError(null);
        } catch (error) {
            console.error('Error fetching service cards', error);
            setError('Wystąpił błąd podczas pobierania kart serwisowych.');
        } finally {
            setLoading(false);
        }
    };

    const fetchMachines = async () => {
        try {
            const response = await getMachines();
            setMachines(response.data);
        } catch (error) {
            console.error('Error fetching machines', error);
            setError('Wystąpił błąd podczas pobierania listy maszyn.');
        }
    };

    const fetchCars = async () => {
        try {
            const response = await getPersonalCars();
            setCars(response.data);
        } catch (error) {
            console.error('Error fetching machines', error);
            setError('Wystąpił błąd podczas pobierania listy aut.');
        }
    };

    const handleAddCard = () => {
        setEditingCard(null);
        setOpenDialog(true);
    };

    const handleEditCard = (card) => {
        setEditingCard(card);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setEditingCard(null);
    };

    const handleSubmit = async (formData) => {
        try {
            if (editingCard) {
                await updateServiceCard(editingCard.id, formData);
            } else {
                await addServiceCard(formData);
            }
            fetchServiceCards();
            handleCloseDialog();
        } catch (error) {
            console.error('Error saving service card', error);
            setError('Wystąpił błąd podczas zapisywania karty serwisowej.');
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                    Karty serwisowe
                </Typography>
                <ServiceCardsList
                    serviceCards={serviceCards}
                    machines={machines}
                    cars={cars}
                    onEdit={handleEditCard}
                    onAdd={handleAddCard}
                />
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>{editingCard ? 'Edytuj kartę serwisową' : 'Dodaj nową kartę serwisową'}</DialogTitle>
                    <DialogContent>
                        <ServiceCardForm
                            card={editingCard}
                            cars={cars}
                            machines={machines}
                            onSubmit={handleSubmit}
                            onClose={handleCloseDialog}
                        />
                    </DialogContent>
                </Dialog>
            </CardContent>
        </Card>
    );
};

export default ServiceCards;