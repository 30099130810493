import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText, IconButton, Button, Box } from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { getPersonalCars } from '../services/apiService';
import { useNavigate } from 'react-router-dom';

const PersonalCarList = () => {
    const [cars, setCars] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCars = async () => {
            try {
                const response = await getPersonalCars();
                setCars(response.data);
            } catch (err) {
                setError('Błąd podczas pobierania samochodów');
                console.error('Error fetching cars:', err);
            }
        };

        fetchCars();
    }, []);

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                    <DirectionsCarIcon /> Twoje samochody osobowe
                </Typography>
                {error && <Typography color="error">{error}</Typography>}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                    <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => navigate('/personal-cars/add')}>
                        Dodaj nowy samochód
                    </Button>
                </Box>
                <List>
                    {cars.map((car) => (
                        <ListItem key={car.id}>
                            <ListItemIcon>
                                <DirectionsCarIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={`${car.producerName} ${car.model}`}
                                secondary={
                                    <>
                                        Typ: {car.typeName}<br />
                                        Rok produkcji: {car.yearOfProduction}, Przebieg: {car.mileage} km
                                    </>
                                }
                            />
                            <IconButton edge="end" onClick={() => navigate(`/personal-cars/edit/${car.id}`)}>
                                <EditIcon />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

export default PersonalCarList;