import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import MenuSidebar from './MenuSidebar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Outlet } from 'react-router-dom';

const Layout = () => {
    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
            <CssBaseline />
            <Header />
            <Box sx={{ display: 'flex', flex: 1 }}>
                <MenuSidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Outlet />
                </Box>
            </Box>
            <Footer />
        </Box>
    );
};

export default Layout;