import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText, IconButton, Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Autocomplete, Chip } from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { getAllReminders, addReminder, updateReminder, deleteReminder, getServiceCards } from '../services/apiService';

const Reminders = () => {
    const [reminders, setReminders] = useState([]);
    const [serviceCards, setServiceCards] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentReminder, setCurrentReminder] = useState(null);
    const [formData, setFormData] = useState({
        title: '',
        message: '',
        reminderDate: '',
        serviceCardIds: []
    });

    useEffect(() => {
        fetchReminders();
        fetchServiceCards();
    }, []);

    const fetchReminders = async () => {
        try {
            const response = await getAllReminders();
            setReminders(response.data);
        } catch (error) {
            console.error('Error fetching reminders:', error);
        }
    };

    const fetchServiceCards = async () => {
        try {
            const response = await getServiceCards();
            setServiceCards(response.data);
        } catch (error) {
            console.error('Error fetching service cards:', error);
        }
    };

    const handleOpen = (reminder = null) => {
        setCurrentReminder(reminder);
        if (reminder) {
            setFormData({
                title: reminder.title,
                message: reminder.message,
                reminderDate: new Date(reminder.reminderDate).toISOString().split('T')[0],
                serviceCardIds: reminder.serviceCardIds || []
            });
        } else {
            setFormData({
                title: '',
                message: '',
                reminderDate: '',
                serviceCardIds: []
            });
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentReminder(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleServiceCardChange = (event, newValue) => {
        setFormData({ ...formData, serviceCardIds: newValue.map(card => card.id) });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (currentReminder) {
                await updateReminder(currentReminder.id, formData);
            } else {
                await addReminder(formData);
            }
            fetchReminders();
            handleClose();
        } catch (error) {
            console.error('Error saving reminder:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteReminder(id);
            fetchReminders();
        } catch (error) {
            console.error('Error deleting reminder:', error);
        }
    };

    return (
        <Card>
            <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h5" component="div">
                        <EventNoteIcon /> Przypomnienia
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => handleOpen()}
                    >
                        Dodaj przypomnienie
                    </Button>
                </Box>
                <List>
                    {reminders.map(reminder => (
                        <ListItem key={reminder.id} secondaryAction={
                            <Box>
                                <IconButton edge="end" aria-label="edit" onClick={() => handleOpen(reminder)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(reminder.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        }>
                            <ListItemIcon>
                                <EventNoteIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={reminder.title}
                                secondary={
                                    <>
                                        <Typography component="span" variant="body2">
                                            {`${new Date(reminder.reminderDate).toLocaleDateString()} - ${reminder.message}`}
                                        </Typography>
                                        <br />
                                        {reminder.serviceCardIds && reminder.serviceCardIds.length > 0 && (
                                            <Typography component="span" variant="body2">
                                                Karty serwisowe: {reminder.serviceCardIds.join(', ')}
                                            </Typography>
                                        )}
                                    </>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </CardContent>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{currentReminder ? 'Edytuj przypomnienie' : 'Dodaj nowe przypomnienie'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="title"
                        label="Tytuł"
                        type="text"
                        fullWidth
                        value={formData.title}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        name="message"
                        label="Wiadomość"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={formData.message}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        name="reminderDate"
                        label="Data przypomnienia"
                        type="date"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={formData.reminderDate}
                        onChange={handleInputChange}
                    />
                    <Autocomplete
                        multiple
                        id="serviceCardIds"
                        options={serviceCards}
                        getOptionLabel={(option) => `${option.action} - ${new Date(option.date_of_service).toLocaleDateString()}`}
                        value={serviceCards.filter(card => formData.serviceCardIds.includes(card.id))}
                        onChange={handleServiceCardChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Karty serwisowe"
                                placeholder="Wybierz karty serwisowe"
                                margin="dense"
                            />
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    variant="outlined"
                                    label={`${option.action} - ${new Date(option.next_service).toLocaleDateString()}`}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Anuluj</Button>
                    <Button onClick={handleSubmit}>Zapisz</Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

export default Reminders;