import React, { useEffect } from 'react';
import { Container, Grid, Typography } from '@mui/material';


const Dashboard = () => {


  useEffect(() => {
    const fetchData = async () => {
      try {

      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Dashboard</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;