import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Switch, FormControlLabel, Button } from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { getNotificationSettings, updateNotificationSettings } from '../services/apiService';

const Notifications = () => {
    const [settings, setSettings] = useState({
        emailNotifications: false,
        smsNotifications: false,
        pushNotifications: false
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await getNotificationSettings();

                // Convert API response (1/0) to boolean
                const formattedSettings = {
                    emailNotifications: !!response.data.email_notifications,
                    smsNotifications: !!response.data.sms_notifications,
                    pushNotifications: !!response.data.push_notifications,
                };

                setSettings(formattedSettings);
            } catch (error) {
                console.error('Error fetching notification settings', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSettings();
    }, []);

    const handleUpdate = async () => {
        try {
            // Convert boolean values back to 1/0 before sending to the API
            const payload = {
                email_notifications: settings.emailNotifications ? 1 : 0,
                sms_notifications: settings.smsNotifications ? 1 : 0,
                push_notifications: settings.pushNotifications ? 1 : 0,
            };

            await updateNotificationSettings(payload);
            alert('Ustawienia powiadomień zaktualizowane.');
        } catch (error) {
            console.error('Error updating notification settings', error);
        }
    };

    if (loading) {
        return <Typography>Ładowanie ustawień...</Typography>;
    }

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="div">
                    <NotificationsActiveIcon /> Powiadomienia
                </Typography>
                <FormControlLabel
                    control={
                        <Switch
                            checked={settings.emailNotifications}
                            onChange={(e) => setSettings({ ...settings, emailNotifications: e.target.checked })}
                        />
                    }
                    label="Otrzymuj powiadomienia email"
                /><br />
                <FormControlLabel
                    control={
                        <Switch
                            checked={settings.smsNotifications}
                            onChange={(e) => setSettings({ ...settings, smsNotifications: e.target.checked })}
                        />
                    }
                    label="Otrzymuj powiadomienia SMS"
                /><br />
                <FormControlLabel
                    control={
                        <Switch
                            checked={settings.pushNotifications}
                            onChange={(e) => setSettings({ ...settings, pushNotifications: e.target.checked })}
                        />
                    }
                    label="Otrzymuj powiadomienia push"
                /><br />
                <Button variant="contained" color="primary" onClick={handleUpdate}>
                    Zaktualizuj ustawienia
                </Button>
            </CardContent>
        </Card>
    );
};

export default Notifications;