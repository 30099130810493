import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import MachineList from './components/MachineList';
import Notifications from './components/Notifications';
import ServiceCards from './components/ServiceCards';
import Reminders from './components/Reminders';
import ProtectedRoute from './components/ProtectedRoute';
import AddMachine from './components/AddMachine';
import EditMachine from './components/EditMachine';
import PersonalCarList from './components/PersonalCarList';
import AddCar from './components/AddCar';
import EditCar from './components/EditCar';

const App = () => (
  <Router>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<ProtectedRoute />}>
        <Route element={<Layout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/machines" element={<MachineList />} />
          <Route path="/machines/add" element={<AddMachine />} />
          <Route path="/personal-cars" element={<PersonalCarList />} />
          <Route path="/personal-cars/add" element={<AddCar />} />
          <Route path="/personal-cars/edit/:id" element={<EditCar />} />
          <Route path="/machines/edit/:id" element={<EditMachine />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/service-cards" element={<ServiceCards />} />
          <Route path="/reminders" element={<Reminders />} />
        </Route>
      </Route>
    </Routes>
  </Router>
);

export default App;