import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BuildIcon from '@mui/icons-material/Build';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CarIcon from '@mui/icons-material/CarRepair';
import { Link } from 'react-router-dom';

const MenuSidebar = () => {
    return (
        <Drawer
            variant="permanent"
            sx={{
                width: 240,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 240,
                    boxSizing: 'border-box',
                },
            }}
        >
            <List>
                <ListItem button component={Link} to="/">
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button component={Link} to="/personal-cars">
                    <ListItemIcon>
                        <CarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Auta" />
                </ListItem>
                <ListItem button component={Link} to="/machines">
                    <ListItemIcon>
                        <AgricultureIcon />
                    </ListItemIcon>
                    <ListItemText primary="Maszyny" />
                </ListItem>

                <ListItem button component={Link} to="/notifications">
                    <ListItemIcon>
                        <NotificationsActiveIcon />
                    </ListItemIcon>
                    <ListItemText primary="Powiadomienia" />
                </ListItem>
                <ListItem button component={Link} to="/service-cards">
                    <ListItemIcon>
                        <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Karty serwisowe" />
                </ListItem>
                <ListItem button component={Link} to="/reminders">
                    <ListItemIcon>
                        <EventNoteIcon />
                    </ListItemIcon>
                    <ListItemText primary="Przypomnienia" />
                </ListItem>
            </List>
        </Drawer>
    );
};

export default MenuSidebar;