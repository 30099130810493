import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText, IconButton, Button, Box, MenuItem, TextField } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { getMachines } from '../services/apiService';
import { useNavigate } from 'react-router-dom';

const MachineList = () => {
    const [machines, setMachines] = useState([]);
    const [filteredMachines, setFilteredMachines] = useState([]);
    const [error, setError] = useState(null);
    const [filters, setFilters] = useState({
        producer: '',
        type: '',
        yearOfProduction: ''
    });
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMachines = async () => {
            try {
                const response = await getMachines();
                setMachines(response.data);
                setFilteredMachines(response.data);
            } catch (err) {
                setError('Błąd podczas pobierania maszyn');
                console.error('Error fetching machines:', err);
            }
        };

        fetchMachines();
    }, []);

    useEffect(() => {
        const applyFilters = () => {
            let result = machines;
            if (filters.producer) {
                result = result.filter(machine => machine.producer.toLowerCase().includes(filters.producer.toLowerCase()));
            }
            if (filters.type) {
                result = result.filter(machine => machine.type.toLowerCase().includes(filters.type.toLowerCase()));
            }
            if (filters.yearOfProduction) {
                result = result.filter(machine => machine.yearOfProduction.toString() === filters.yearOfProduction);
            }
            setFilteredMachines(result);
        };

        applyFilters();
    }, [filters, machines]);

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters(prev => ({ ...prev, [name]: value }));
    };

    const uniqueProducers = ['', ...new Set(machines.map(machine => machine.producer))];
    const uniqueTypes = ['', ...new Set(machines.map(machine => machine.type))];
    const uniqueYears = ['', ...new Set(machines.map(machine => machine.yearOfProduction.toString()))].sort((a, b) => b.localeCompare(a));

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                    <BuildIcon /> Twoje maszyny
                </Typography>
                {error && <Typography color="error">{error}</Typography>}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: '20px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
                        <Box sx={{ display: 'flex', gap: 2, flexGrow: 1, maxWidth: 'calc(100% - 200px)' }}>
                            <TextField
                                select
                                label="Producent"
                                name="producer"
                                value={filters.producer}
                                onChange={handleFilterChange}
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: '150px', flexGrow: 1 }}
                            >
                                {uniqueProducers.map((producer) => (
                                    <MenuItem key={producer} value={producer}>
                                        {producer || 'Wszystkie'}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                select
                                label="Typ"
                                name="type"
                                value={filters.type}
                                onChange={handleFilterChange}
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: '150px', flexGrow: 1 }}
                            >
                                {uniqueTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type || 'Wszystkie'}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                select
                                label="Rok produkcji"
                                name="yearOfProduction"
                                value={filters.yearOfProduction}
                                onChange={handleFilterChange}
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: '150px', flexGrow: 1 }}
                            >
                                {uniqueYears.map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {year || 'Wszystkie'}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => navigate('/machines/add')}>
                            Dodaj nową maszynę
                        </Button>
                    </Box>
                </Box>
                <List>
                    {filteredMachines.map((machine) => (
                        <ListItem key={machine.id}>
                            <ListItemIcon>
                                <BuildIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={`${machine.producer} - ${machine.type}`}
                                secondary={machine.yearOfProduction ? `Rok produkcji: ${machine.yearOfProduction}` : null}
                            />
                            <IconButton edge="end" onClick={() => navigate(`/machines/edit/${machine.id}`)}>
                                <EditIcon />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

export default MachineList;