import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Box,
    Typography,
    MenuItem,
    CircularProgress,
    Grid,
    Divider,
    Paper
} from '@mui/material';
import { addPersonalCar, updatePersonalCar, getPersonalCarDetails, getCarProducers, getCarTypes } from '../services/apiService';
import { useNavigate, useParams } from 'react-router-dom';

const PersonalCarForm = ({ isEditMode = false }) => {
    const [car, setCar] = useState({
        producer_id: '',
        type_id: '',
        model: '',
        yearOfProduction: '',
        VIN: '',
        mileage: '',
        lastServiceDate: '',
        registrationNumber: '',
        insuranceExpiryDate: '',
        technicalInspectionDate: '',
        fuelType: '',
        averageFuelConsumption: '',
        currentDriver: '',
        department: '',
        purchaseDate: '',
        purchasePrice: '',
        leasingEndDate: '',
        maintenanceCosts: '',
        nextServiceDate: '',
        tireChangeDate: '',
        gpsTrackingId: '',
        notes: ''
    });
    const [producers, setProducers] = useState([]);
    const [types, setTypes] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { id } = useParams();

    // Walidacja formularza
    const validateForm = () => {
        if (!car.producer_id || !car.model || !car.yearOfProduction || !car.VIN || !car.mileage) {
            return 'Wszystkie wymagane pola muszą być wypełnione.';
        }
        if (isNaN(car.yearOfProduction) || car.yearOfProduction <= 1885 || car.yearOfProduction > new Date().getFullYear()) {
            return 'Nieprawidłowy rok produkcji.';
        }
        if (isNaN(car.mileage) || car.mileage < 0) {
            return 'Przebieg musi być liczbą dodatnią.';
        }
        if (car.purchasePrice && (isNaN(car.purchasePrice) || car.purchasePrice < 0)) {
            return 'Cena zakupu musi być liczbą dodatnią.';
        }
        if (car.maintenanceCosts && (isNaN(car.maintenanceCosts) || car.maintenanceCosts < 0)) {
            return 'Koszty utrzymania muszą być liczbą dodatnią.';
        }
        if (car.averageFuelConsumption && (isNaN(car.averageFuelConsumption) || car.averageFuelConsumption < 0)) {
            return 'Średnie zużycie paliwa musi być liczbą dodatnią.';
        }
        return null;
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const [producersResponse, typesResponse] = await Promise.all([
                    getCarProducers(),
                    getCarTypes()
                ]);
                setProducers(producersResponse.data || []);
                setTypes(typesResponse.data || []);

                if (isEditMode && id) {
                    const carResponse = await getPersonalCarDetails(id);
                    if (carResponse && carResponse.data) {
                        setCar(prevCar => ({
                            ...prevCar,
                            ...carResponse.data,
                            producer_id: carResponse.data.producer_id ? carResponse.data.producer_id.toString() : '',
                            type_id: carResponse.data.type_id ? carResponse.data.type_id.toString() : '',
                            yearOfProduction: carResponse.data.yearOfProduction ? carResponse.data.yearOfProduction.toString() : '',
                            mileage: carResponse.data.mileage ? carResponse.data.mileage.toString() : '',
                            lastServiceDate: carResponse.data.lastServiceDate ? carResponse.data.lastServiceDate.split('T')[0] : ''
                        }));
                    }
                }
            } catch (error) {
                setErrorMessage('Błąd podczas pobierania danych.');
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [isEditMode, id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCar(prevCar => ({ ...prevCar, [name]: value }));
    };

    const handleSubmit = async () => {
        const validationError = validateForm();
        if (validationError) {
            setErrorMessage(validationError);
            return;
        }

        // Sprawdzenie, czy mileage jest liczbą całkowitą
        if (isNaN(car.mileage) || parseInt(car.mileage, 10) <= 0) {
            setErrorMessage('Przebieg musi być liczbą dodatnią.');
            return;
        }


        try {
            const carData = {
                ...car,
                yearOfProduction: car.yearOfProduction ? parseInt(car.yearOfProduction, 10) : null,
                mileage: car.mileage ? parseInt(car.mileage, 10) : null, // Upewnij się, że mileage jest liczbą całkowitą
                producer_id: car.producer_id ? parseInt(car.producer_id, 10) : null,
                type_id: car.type_id ? parseInt(car.type_id, 10) : null,
                purchasePrice: car.purchasePrice ? parseFloat(car.purchasePrice) : null,
                maintenanceCosts: car.maintenanceCosts ? parseFloat(car.maintenanceCosts) : null,
                averageFuelConsumption: car.averageFuelConsumption ? parseFloat(car.averageFuelConsumption) : null
            };
            if (isEditMode) {
                await updatePersonalCar(id, carData);
            } else {
                await addPersonalCar(carData);
            }
            navigate('/personal-cars');
        } catch (error) {
            setErrorMessage('Błąd podczas zapisywania danych.');
            console.error('Error saving car:', error);
        }
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <Paper elevation={3} sx={{ p: 4, maxWidth: 800, margin: 'auto' }}>
            <Typography variant="h4" gutterBottom>
                {isEditMode ? 'Edycja samochodu' : 'Dodaj nowy samochód'}
            </Typography>
            <form>
                <Grid container spacing={3}>
                    {/* Sekcja: Informacje podstawowe */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Informacje podstawowe</Typography>
                        <Divider sx={{ mb: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            fullWidth
                            label="Producent"
                            name="producer_id"
                            value={car.producer_id || ''}
                            onChange={handleInputChange}
                            required
                        >
                            {producers.map((producer) => (
                                <MenuItem key={producer.id} value={producer.id.toString()}>
                                    {producer.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            fullWidth
                            label="Typ samochodu"
                            name="type_id"
                            value={car.type_id || ''}
                            onChange={handleInputChange}
                            required
                        >
                            {types.map((type) => (
                                <MenuItem key={type.id} value={type.id.toString()}>
                                    {type.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Model"
                            name="model"
                            value={car.model || ''}
                            onChange={handleInputChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Rok produkcji"
                            name="yearOfProduction"
                            value={car.yearOfProduction || ''}
                            onChange={handleInputChange}
                            type="number"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="VIN"
                            name="VIN"
                            value={car.VIN || ''}
                            onChange={handleInputChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Numer rejestracyjny"
                            name="registrationNumber"
                            value={car.registrationNumber || ''}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    {/* Sekcja: Eksploatacja */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Eksploatacja</Typography>
                        <Divider sx={{ mb: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Przebieg"
                            name="mileage"
                            value={car.mileage || ''}
                            onChange={handleInputChange}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            fullWidth
                            label="Rodzaj paliwa"
                            name="fuelType"
                            value={car.fuelType || ''}
                            onChange={handleInputChange}
                        >
                            {['Benzyna', 'Diesel', 'LPG', 'Elektryczny', 'Hybryda'].map((fuel) => (
                                <MenuItem key={fuel} value={fuel}>
                                    {fuel}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Średnie zużycie paliwa (l/100km)"
                            name="averageFuelConsumption"
                            value={car.averageFuelConsumption || ''}
                            onChange={handleInputChange}
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Aktualny kierowca"
                            name="currentDriver"
                            value={car.currentDriver || ''}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Dział"
                            name="department"
                            value={car.department || ''}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    {/* Sekcja: Serwis i ubezpieczenie */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Serwis i ubezpieczenie</Typography>
                        <Divider sx={{ mb: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Data ostatniego serwisu"
                            name="lastServiceDate"
                            type="date"
                            value={car.lastServiceDate || ''}
                            onChange={handleInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Data następnego serwisu"
                            name="nextServiceDate"
                            type="date"
                            value={car.nextServiceDate || ''}
                            onChange={handleInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Data wygaśnięcia ubezpieczenia"
                            name="insuranceExpiryDate"
                            type="date"
                            value={car.insuranceExpiryDate || ''}
                            onChange={handleInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Data następnego przeglądu technicznego"
                            name="technicalInspectionDate"
                            type="date"
                            value={car.technicalInspectionDate || ''}
                            onChange={handleInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Data wymiany opon"
                            name="tireChangeDate"
                            type="date"
                            value={car.tireChangeDate || ''}
                            onChange={handleInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    {/* Sekcja: Informacje finansowe */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Informacje finansowe</Typography>
                        <Divider sx={{ mb: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Data zakupu"
                            name="purchaseDate"
                            type="date"
                            value={car.purchaseDate || ''}
                            onChange={handleInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Cena zakupu"
                            name="purchasePrice"
                            value={car.purchasePrice || ''}
                            onChange={handleInputChange}
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Data zakończenia leasingu"
                            name="leasingEndDate"
                            type="date"
                            value={car.leasingEndDate || ''}
                            onChange={handleInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Koszty utrzymania (rocznie)"
                            name="maintenanceCosts"
                            value={car.maintenanceCosts || ''}
                            onChange={handleInputChange}
                            type="number"
                        />
                    </Grid>

                    {/* Sekcja: Dodatkowe informacje */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Dodatkowe informacje</Typography>
                        <Divider sx={{ mb: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="ID urządzenia GPS"
                            name="gpsTrackingId"
                            value={car.gpsTrackingId || ''}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Uwagi"
                            name="notes"
                            value={car.notes || ''}
                            onChange={handleInputChange}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>

                {errorMessage && <Typography color="error" sx={{ mt: 2 }}>{errorMessage}</Typography>}

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        {isEditMode ? 'Zapisz zmiany' : 'Dodaj samochód'}
                    </Button>
                </Box>
            </form>
        </Paper>
    );
};

export default PersonalCarForm;
