import React, { useState, useEffect } from 'react';
import { TextField, Button, DialogActions, Select, MenuItem, FormControl, InputLabel, Box, Typography, CircularProgress } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const ServiceCardForm = ({ card, machines, cars, onSubmit, onClose }) => {
    const [formData, setFormData] = useState({
        type: '',  // New field for selecting auto or machine
        machine_id: '',
        auto_id: '',  // New field for auto_id
        action: '',
        description: '',
        date_of_service: '',
        next_service: '',
        spareParts: []
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (card) {
            setFormData({
                ...card,
                type: card.machine_id ? 'maszyna' : 'auto',
                date_of_service: card.date_of_service ? new Date(card.date_of_service).toISOString().split('T')[0] : '',
                next_service: card.next_service ? new Date(card.next_service).toISOString().split('T')[0] : '',
                spareParts: card.spareParts || []
            });
        }
    }, [card]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handleAddSparePart = () => {
        setFormData({
            ...formData,
            spareParts: [...formData.spareParts, { id: uuidv4(), name: '', partNumber: '' }]
        });
    };

    const handleSparePartChange = (i, field, value) => {
        const updatedSpareParts = [...formData.spareParts];
        updatedSpareParts[i][field] = value;
        setFormData({ ...formData, spareParts: updatedSpareParts });
        setErrors({ ...errors, spareParts: '' });
    };

    const handleRemoveSparePart = (i) => {
        const updatedSpareParts = [...formData.spareParts];
        updatedSpareParts.splice(i, 1);
        setFormData({ ...formData, spareParts: updatedSpareParts });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.type) newErrors.type = 'Wybierz typ (auto lub maszyna)';
        if (formData.type === 'auto' && !formData.auto_id) newErrors.auto_id = 'Wybierz auto';
        if (formData.type === 'maszyna' && !formData.machine_id) newErrors.machine_id = 'Wybierz maszynę';
        if (!formData.action) newErrors.action = 'Podaj akcję serwisową';
        if (!formData.date_of_service) newErrors.date_of_service = 'Wybierz datę serwisu';

        const serviceDate = new Date(formData.date_of_service);
        const nextServiceDate = new Date(formData.next_service);
        if (formData.next_service && nextServiceDate <= serviceDate) {
            newErrors.next_service = 'Data następnego serwisu musi być późniejsza niż data serwisu';
        }

        const invalidParts = formData.spareParts.filter(part => !part.name || !part.partNumber);
        if (invalidParts.length > 0) {
            newErrors.spareParts = 'Wszystkie pola części zamiennych muszą być wypełnione';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsSubmitting(true);
            try {
                // Dynamically send either machine_id or auto_id depending on selected type
                const payload = {
                    ...formData,
                    machine_id: formData.type === 'maszyna' ? formData.machine_id : null,
                    auto_id: formData.type === 'auto' ? formData.auto_id : null,
                    spareParts: formData.spareParts.filter((part) => part.name && part.partNumber)
                };

                await onSubmit(payload);
                onClose();
            } catch (error) {
                setErrors({ ...errors, submit: `Wystąpił błąd podczas zapisywania: ${error.message}` });
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="normal" error={!!errors.type}>
                <InputLabel id="type-select-label">Wybierz typ</InputLabel>
                <Select
                    labelId="type-select-label"
                    id="type-select"
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    label="Typ"
                >
                    <MenuItem value="auto">Auto</MenuItem>
                    <MenuItem value="maszyna">Maszyna</MenuItem>
                </Select>
                {errors.type && <Typography color="error">{errors.type}</Typography>}
            </FormControl>

            {formData.type === 'auto' && (
                <FormControl fullWidth margin="normal" error={!!errors.auto_id}>
                    <InputLabel id="car-select-label">Auto</InputLabel>
                    <Select
                        labelId="car-select-label"
                        id="car-select"
                        name="auto_id"
                        value={formData.auto_id}
                        onChange={handleChange}
                        label="Auto"
                    >
                        {cars && cars.length > 0 ? (
                            cars.map((car) => (
                                <MenuItem key={car.id} value={car.id}>
                                    {`${car.producerName} - ${car.model} (${car.yearOfProduction})`}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem value="">Brak dostępnych aut</MenuItem>
                        )}
                    </Select>
                    {errors.auto_id && <Typography color="error">{errors.auto_id}</Typography>}
                </FormControl>
            )}

            {formData.type === 'maszyna' && (
                <FormControl fullWidth margin="normal" error={!!errors.machine_id}>
                    <InputLabel id="machine-select-label">Maszyna</InputLabel>
                    <Select
                        labelId="machine-select-label"
                        id="machine-select"
                        name="machine_id"
                        value={formData.machine_id}
                        onChange={handleChange}
                        label="Maszyna"
                    >
                        {machines && machines.length > 0 ? (
                            machines.map((machine) => (
                                <MenuItem key={machine.id} value={machine.id}>
                                    {`${machine.producer} - ${machine.type} (${machine.yearOfProduction})`}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem value="">Brak dostępnych maszyn</MenuItem>
                        )}
                    </Select>
                    {errors.machine_id && <Typography color="error">{errors.machine_id}</Typography>}
                </FormControl>
            )}

            {/* Pozostałe pola formularza */}
            <TextField
                fullWidth
                margin="normal"
                name="action"
                label="Akcja"
                value={formData.action}
                onChange={handleChange}
                error={!!errors.action}
                helperText={errors.action}
            />
            <TextField
                fullWidth
                margin="normal"
                name="description"
                label="Opis (opcjonalnie)"
                multiline
                rows={4}
                value={formData.description}
                onChange={handleChange}
            />
            <TextField
                fullWidth
                margin="normal"
                name="date_of_service"
                label="Data serwisu"
                type="date"
                value={formData.date_of_service}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                error={!!errors.date_of_service}
                helperText={errors.date_of_service}
            />
            <TextField
                fullWidth
                margin="normal"
                name="next_service"
                label="Następny serwis"
                type="date"
                value={formData.next_service}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                error={!!errors.next_service}
                helperText={errors.next_service}
            />
            {/* Części zamienne */}
            <Box>
                <Typography variant="h6">Części zamienne</Typography>
                {formData.spareParts.map((part, i) => (
                    <Box key={part.id} display="flex" alignItems="center" mb={2}>
                        <TextField
                            name={`spareParts[${i}].name`}
                            label="Nazwa części"
                            value={part.name}
                            onChange={(e) => handleSparePartChange(i, 'name', e.target.value)}
                            sx={{ marginRight: 2 }}
                            error={!!errors.spareParts}
                        />
                        <TextField
                            name={`spareParts[${i}].partNumber`}
                            label="Numer katalogowy"
                            value={part.partNumber}
                            onChange={(e) => handleSparePartChange(i, 'partNumber', e.target.value)}
                            sx={{ marginRight: 2 }}
                            error={!!errors.spareParts}
                        />
                        <Button onClick={() => handleRemoveSparePart(i)}>Usuń</Button>
                    </Box>
                ))}
                {errors.spareParts && <Typography color="error">{errors.spareParts}</Typography>}
                <Button onClick={handleAddSparePart}>Dodaj część</Button>
            </Box>
            <DialogActions>
                <Button onClick={onClose} disabled={isSubmitting}>Anuluj</Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                    {isSubmitting ? <CircularProgress size={24} /> : (card ? 'Zapisz zmiany' : 'Dodaj')}
                </Button>
            </DialogActions>
            {errors.submit && <Typography color="error">{errors.submit}</Typography>}
        </form>
    );
};

export default ServiceCardForm;